import React from 'react';
import { MaterialsProvider } from "../components/Orders/MaterialsContext";
import { UserProvider } from "./UserContext";
import { BusinessProvider } from "./BusinessContext";
import { ProjectProvider } from "../components/home/ProjectContext";
import { FlagProvider } from "../flag-context";
import { AuthProvider } from "./AuthContext";


const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <UserProvider>
        <MaterialsProvider>
            <BusinessProvider>
              <ProjectProvider>
                <FlagProvider>
                    {children}
                </FlagProvider>
              </ProjectProvider>
            </BusinessProvider>
        </MaterialsProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export default AppProvider;
