import React, {useContext} from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const PrivateRoute = ({ children }) => {
  const { authenticated } = useContext(AuthContext);
  return authenticated ? children || <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
