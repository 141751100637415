import React, { Suspense, useContext } from "react";
import { Box } from "@mui/material";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import AppProvider from "./context/AppProvider";
import PrivateRoute from "./auth/PrivateRoute";
import {isAuthenticated} from "./auth/authUtils";
import getEnvironmentMode from "./utils/environmentUtil";
// Lazy load the components
const HomePage = React.lazy(() => import("./components/home/home"));
const MiniDrawer = React.lazy(() => import("./header"));
const DrawingApp = React.lazy(() => import("./components/design-screen/drawingApp"));
const Viewer = React.lazy(() => import("./components/design-screen/3D/index"));
const UploadScreen = React.lazy(() => import("./components/upload-screen/uploadScreen"));
const ProjectForm = React.lazy(() => import("./components/home/project-form"));
const Products = React.lazy(() => import("./components/products"));
const Orders = React.lazy(() => import("./components/Orders/Orders"));
const LoginWithSSO = React.lazy(() => import("./auth/LoginWithSSO"));
const Login = React.lazy(() => import("./auth/Login"));
const ThreeDXFViewer = React.lazy(() => import("./components/three-dxf-viewer/ThreeDXFViewer"));

function App() {
  const authenticated = isAuthenticated();
  const environmentmode = getEnvironmentMode();
  return (
      <AppProvider>
        <Router>
          <div style={{ display: "flex", width: "100%" }}>
            {<Suspense fallback={<></>}><MiniDrawer /></Suspense>}
            <main style={{ flexGrow: 1, padding: "16px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "90vh",
                  float: "left",
                  width : "100%"
                }}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    {/* Public routes */}
                    {
                      environmentmode === 'local' 
                      ? <Route path="/" element={<Login />} />
                      : <Route path="/" element={authenticated ? <Navigate to="/home" /> : <LoginWithSSO />} />
                    }
                    {/* Protected routes */}
                    <Route element={<PrivateRoute />}>
                      <Route path="/home" element={<HomePage />} />
                      <Route path="/draw" element={<UploadScreen />} />
                      <Route path="/designWithBg" element={<ThreeDXFViewer />} />
                      <Route path="/designWithoutBg" element={<DrawingApp />} />
                      <Route path="/3dViewer" element={<Viewer />} />
                      <Route path="/project" element={<ProjectForm />} />
                      <Route path="/orders" element={<Orders />} />
                      <Route path="/windows" element={<Products />} />
                      <Route path="/3dViewerWithBg" element={<Viewer />} />
                      <Route path="/OrdersWithBg" element={<Orders />} />
                    </Route>

                    {/* Catch-all for unhandled routes */}
                    <Route path="*" element={<div>Page Not Found</div>} />
                  </Routes>
                </Suspense>
              </Box>
            </main>
          </div>
        </Router>
      </AppProvider>
  );
}

export default App;
