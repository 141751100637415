import React, { createContext, useContext, useState } from 'react';

// Create context
const MaterialsContext = createContext();

// MaterialsProvider to manage state across pages
export const MaterialsProvider = ({ children }) => {
  const [Added_Materials, setAdded_Materials] = useState([]);

  return (
    <MaterialsContext.Provider value={{ Added_Materials, setAdded_Materials }}>
      {children}
    </MaterialsContext.Provider>
  );
};

// Custom hook to access context
export const useMaterials = () => {
  return useContext(MaterialsContext);
};