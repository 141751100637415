import React, { createContext, useState, useEffect } from "react";
import { isAuthenticated, setToken, clearToken } from "../auth/authUtils";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(isAuthenticated());

  useEffect(() => {
    const token = isAuthenticated();
    setAuthenticated(token);
  }, []);

  const login = (token) => {
    setAuthenticated(true);
    setToken(token);
  };

  const logout = () => {
    setAuthenticated(false);
    clearToken();
  };

  return (
    <AuthContext.Provider value={{ authenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
