// FlagContext.js
import React, { createContext, useEffect, useState } from 'react';

const initialFlagState = {
    Properties: {        
    },
  Properties1: {
    "systems": [
        {
            "system_id": 16,
            "system_name": "Sleek",
            "products": [
                {
                    "product_id": 8,
                    "product_name": "Partition",
                    "templates": [
                        {
                            "template_id": 11,
                            "template_name": "Fixed Partition",
                            "template_img_url": null,
                            "attributes": [
                                {
                                    "component_attribute_id": 6,
                                    "component_attribute_name": "Partition Height",
                                    "component_id": 16,
                                    "component_type": "text",
                                    "component_name": "",
                                    "min": 2000,
                                    "max": 3000,
                                    "defaultValue": 2400
                                },
                                {
                                    "component_attribute_id": 7,
                                    "component_attribute_name": "Profile Finish",
                                    "component_id": 15,
                                    "component_type": "dropdown",
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 4,
                                            "comp_attribute_value_name": "Raw Mill",
                                            "comp_attribute_value": "4",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 5,
                                            "comp_attribute_value_name": "Black Matt",
                                            "comp_attribute_value": "5",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 6,
                                            "comp_attribute_value_name": "Natural Anodized",
                                            "comp_attribute_value": "6",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Champagne",
                                            "comp_attribute_value": "7",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Rose Gold",
                                            "comp_attribute_value": "8",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "Customised RAL colours",
                                            "comp_attribute_value": "9",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 5,
                                    "component_attribute_name": "Glass Type",
                                    "component_id": 14,
                                    "component_type": "dropdown",
                                    "component_name": "",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "Toughend Glass",
                                            "comp_attribute_value": "9",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "Laminated Glass",
                                            "comp_attribute_value": "9",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 8,
                                    "component_attribute_name": "Glass Thickness",
                                    "component_id": 16,
                                    "component_type": "dropdown",
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "10 mm",
                                            "acoustic_value": 34,
                                            "comp_attribute_value": "9",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 10,
                                            "comp_attribute_value_name": "11.52 PVB",
                                            "acoustic_value": 34,
                                            "comp_attribute_value": "10",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 11,
                                            "comp_attribute_value_name": "11.52 APVB",
                                            "acoustic_value": 34,
                                            "comp_attribute_value": "11",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 12,
                                            "comp_attribute_value_name": "12 mm",
                                            "acoustic_value": 36,
                                            "comp_attribute_value": "12",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 13,
                                            "comp_attribute_value_name": "12.52 PVB",
                                            "acoustic_value": 36,
                                            "comp_attribute_value": "13",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 14,
                                            "comp_attribute_value_name": "12.52 APVB",
                                            "acoustic_value": 36,
                                            "comp_attribute_value": "14",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 15,
                                            "comp_attribute_value_name": "13.52 PVB",
                                            "acoustic_value": 38,
                                            "comp_attribute_value": "15",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 16,
                                            "comp_attribute_value_name": "13.52 APVB",
                                            "acoustic_value": 36,
                                            "comp_attribute_value": "16",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 9,
                                    "component_attribute_name": "Acoustic Value",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "component_type": "slider",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 14,
                                            "comp_attribute_value_name": 34,
                                            "comp_attribute_value": "14",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 15,
                                            "comp_attribute_value_name": 36,
                                            "comp_attribute_value": "15",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 16,
                                            "comp_attribute_value_name": 38,
                                            "comp_attribute_value": "16",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 11,
                                    "component_attribute_name": "Glass Joint",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "component_type": "dropdown",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 22,
                                            "comp_attribute_value_name": "Two-way Polycarbonate",
                                            "comp_attribute_value": "22",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 23,
                                            "comp_attribute_value_name": "3-way Polycarbonate",
                                            "comp_attribute_value": "23",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 24,
                                            "comp_attribute_value_name": "Mullion",
                                            "comp_attribute_value": "24",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 25,
                                            "comp_attribute_value_name": "90 degree Aluminium",
                                            "comp_attribute_value": "25",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 14,
                                    "component_attribute_name": "Gasket Color",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "component_type": "dropdown",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 22,
                                            "comp_attribute_value_name": "Default Black",
                                            "comp_attribute_value": "22",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 23,
                                            "comp_attribute_value_name": "Optional Gray",
                                            "comp_attribute_value": "23",
                                            "isDefault": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "template_id": 12,
                            "template_name": "Partition with Jcap",
                            "template_img_url": null,
                            "attributes": [
                                {
                                    "component_attribute_id": 6,
                                    "component_attribute_name": "Profile Constant",
                                    "component_id": 15,
                                    "component_type": "dropdown",
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 1,
                                            "comp_attribute_value_name": "U- Profile",
                                            "comp_attribute_value": "1",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 2,
                                            "comp_attribute_value_name": "Clip on Profile ",
                                            "comp_attribute_value": "2",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 3,
                                            "comp_attribute_value_name": "90 Degree Alu Profile",
                                            "comp_attribute_value": "3",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 7,
                                    "component_attribute_name": "Profile Finish",
                                    "component_id": 15,
                                    "component_type": "dropdown",
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 4,
                                            "comp_attribute_value_name": "Raw Mill",
                                            "comp_attribute_value": "4",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 5,
                                            "comp_attribute_value_name": "Black",
                                            "comp_attribute_value": "5",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 6,
                                            "comp_attribute_value_name": "Natural Anodized",
                                            "comp_attribute_value": "6",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Champagne",
                                            "comp_attribute_value": "7",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Rose Gold",
                                            "comp_attribute_value": "8",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "Customised RAL colours",
                                            "comp_attribute_value": "9",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 8,
                                    "component_attribute_name": "Glass Thickness",
                                    "component_id": 16,
                                    "component_type": "dropdown",
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 10,
                                            "comp_attribute_value_name": "11.52 PVB/APVB",
                                            "comp_attribute_value": "10",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 11,
                                            "comp_attribute_value_name": "12",
                                            "comp_attribute_value": "11",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 12,
                                            "comp_attribute_value_name": "12.52 PVB/APVB",
                                            "comp_attribute_value": "12",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 13,
                                            "comp_attribute_value_name": "13.52 PVB/APVB",
                                            "comp_attribute_value": "13",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 9,
                                    "component_attribute_name": "Acoustic Value",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 14,
                                            "comp_attribute_value_name": "34dB",
                                            "comp_attribute_value": "14",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 15,
                                            "comp_attribute_value_name": "36dB",
                                            "comp_attribute_value": "15",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 16,
                                            "comp_attribute_value_name": "38dB/36dB",
                                            "comp_attribute_value": "16",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 10,
                                    "component_attribute_name": "Polycarbonates Size",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 17,
                                            "comp_attribute_value_name": "10",
                                            "comp_attribute_value": "17",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 18,
                                            "comp_attribute_value_name": "11.52",
                                            "comp_attribute_value": "18",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 19,
                                            "comp_attribute_value_name": "12.52",
                                            "comp_attribute_value": "19",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 20,
                                            "comp_attribute_value_name": "13.52",
                                            "comp_attribute_value": "20",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 21,
                                            "comp_attribute_value_name": "Customized",
                                            "comp_attribute_value": "21",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 11,
                                    "component_attribute_name": "Polycarbonates Shape",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 22,
                                            "comp_attribute_value_name": "2-Way",
                                            "comp_attribute_value": "22",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 23,
                                            "comp_attribute_value_name": "3-Way",
                                            "comp_attribute_value": "23",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 24,
                                            "comp_attribute_value_name": "90 Degree",
                                            "comp_attribute_value": "24",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 25,
                                            "comp_attribute_value_name": "Customised",
                                            "comp_attribute_value": "25",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 13,
                                    "component_attribute_name": "Gasket",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 14,
                                    "component_attribute_name": "Gasket Color",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 15,
                                    "component_attribute_name": "Portal",
                                    "component_id": 19,
                                    "component_name": "Accessories",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 26,
                                            "comp_attribute_value_name": "100 X 100 Portal",
                                            "comp_attribute_value": "26",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 27,
                                            "comp_attribute_value_name": "200 X 100 Portal",
                                            "comp_attribute_value": "27",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 28,
                                            "comp_attribute_value_name": "300 X 100portal",
                                            "comp_attribute_value": "28",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 29,
                                            "comp_attribute_value_name": "80 X 70 portal",
                                            "comp_attribute_value": "29",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 16,
                                    "component_attribute_name": "Door Types",
                                    "component_id": 20,
                                    "component_name": "Door Types",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 30,
                                            "comp_attribute_value_name": "Stile Single-leaf Door",
                                            "comp_attribute_value": "30",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 31,
                                            "comp_attribute_value_name": "Sliding Door",
                                            "comp_attribute_value": "31",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 32,
                                            "comp_attribute_value_name": "Patch Fitting Door",
                                            "comp_attribute_value": "32",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 33,
                                            "comp_attribute_value_name": "Frameless hinged glass door",
                                            "comp_attribute_value": "33",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 34,
                                            "comp_attribute_value_name": "Bifold Door",
                                            "comp_attribute_value": "34",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 35,
                                            "comp_attribute_value_name": "Stile Double-leaf Door",
                                            "comp_attribute_value": "35",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 17,
                                    "component_attribute_name": "Single Glazed Profile (82 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 18,
                                    "component_attribute_name": "Single Glazed Gass Profile (82 X 40) Type -2",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 19,
                                    "component_attribute_name": "Single Glazed Profile (150 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 20,
                                    "component_attribute_name": "Double glazed Profile (82 X 55)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Frame (55 X 32)",
                                    "component_id": 21,
                                    "component_name": "Door Frame",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 22,
                                    "component_attribute_name": "Door Hinges",
                                    "component_id": 22,
                                    "component_name": "Door Hinges",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 23,
                                    "component_attribute_name": "Door Lock",
                                    "component_id": 23,
                                    "component_name": "Door lock",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 24,
                                    "component_attribute_name": "Colour",
                                    "component_id": 24,
                                    "component_name": "Color",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 36,
                                            "comp_attribute_value_name": "SS, black",
                                            "comp_attribute_value": "36",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 37,
                                            "comp_attribute_value_name": "White",
                                            "comp_attribute_value": "37",
                                            "isDefault": true
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "template_id": 13,
                            "template_name": "Partition with stick-on profile",
                            "template_img_url": null,
                            "attributes": [
                                {
                                    "component_attribute_id": 6,
                                    "component_attribute_name": "Profile Constant",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 1,
                                            "comp_attribute_value_name": "U- Profile",
                                            "comp_attribute_value": "1",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 2,
                                            "comp_attribute_value_name": "Clip on Profile ",
                                            "comp_attribute_value": "2",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 3,
                                            "comp_attribute_value_name": "90 Degree Alu Profile",
                                            "comp_attribute_value": "3",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 7,
                                    "component_attribute_name": "Profile Finish",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 4,
                                            "comp_attribute_value_name": "Raw Mill",
                                            "comp_attribute_value": "4",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 5,
                                            "comp_attribute_value_name": "Black",
                                            "comp_attribute_value": "5",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 6,
                                            "comp_attribute_value_name": "Natural Anodized",
                                            "comp_attribute_value": "6",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Champagne",
                                            "comp_attribute_value": "7",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Rose Gold",
                                            "comp_attribute_value": "8",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "Customised RAL colours",
                                            "comp_attribute_value": "9",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 8,
                                    "component_attribute_name": "Glass Thickness",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 10,
                                            "comp_attribute_value_name": "11.52 PVB/APVB",
                                            "comp_attribute_value": "10",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 11,
                                            "comp_attribute_value_name": "12",
                                            "comp_attribute_value": "11",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 12,
                                            "comp_attribute_value_name": "12.52 PVB/APVB",
                                            "comp_attribute_value": "12",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 13,
                                            "comp_attribute_value_name": "13.52 PVB/APVB",
                                            "comp_attribute_value": "13",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 9,
                                    "component_attribute_name": "Acoustic Value",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 14,
                                            "comp_attribute_value_name": "34dB",
                                            "comp_attribute_value": "14",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 15,
                                            "comp_attribute_value_name": "36dB",
                                            "comp_attribute_value": "15",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 16,
                                            "comp_attribute_value_name": "38dB/36dB",
                                            "comp_attribute_value": "16",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 10,
                                    "component_attribute_name": "Polycarbonates Size",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 17,
                                            "comp_attribute_value_name": "10",
                                            "comp_attribute_value": "17",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 18,
                                            "comp_attribute_value_name": "11.52",
                                            "comp_attribute_value": "18",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 19,
                                            "comp_attribute_value_name": "12.52",
                                            "comp_attribute_value": "19",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 20,
                                            "comp_attribute_value_name": "13.52",
                                            "comp_attribute_value": "20",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 21,
                                            "comp_attribute_value_name": "Customized",
                                            "comp_attribute_value": "21",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 11,
                                    "component_attribute_name": "Polycarbonates Shape",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 22,
                                            "comp_attribute_value_name": "2-Way",
                                            "comp_attribute_value": "22",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 23,
                                            "comp_attribute_value_name": "3-Way",
                                            "comp_attribute_value": "23",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 24,
                                            "comp_attribute_value_name": "90 Degree",
                                            "comp_attribute_value": "24",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 25,
                                            "comp_attribute_value_name": "Customised",
                                            "comp_attribute_value": "25",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 13,
                                    "component_attribute_name": "Gasket",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 14,
                                    "component_attribute_name": "Gasket Color",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 15,
                                    "component_attribute_name": "Portal",
                                    "component_id": 19,
                                    "component_name": "Accessories",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 26,
                                            "comp_attribute_value_name": "100 X 100 Portal",
                                            "comp_attribute_value": "26",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 27,
                                            "comp_attribute_value_name": "200 X 100 Portal",
                                            "comp_attribute_value": "27",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 28,
                                            "comp_attribute_value_name": "300 X 100portal",
                                            "comp_attribute_value": "28",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 29,
                                            "comp_attribute_value_name": "80 X 70 portal",
                                            "comp_attribute_value": "29",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 16,
                                    "component_attribute_name": "Door Types",
                                    "component_id": 20,
                                    "component_name": "Door Types",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 30,
                                            "comp_attribute_value_name": "Stile Single-leaf Door",
                                            "comp_attribute_value": "30",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 31,
                                            "comp_attribute_value_name": "Sliding Door",
                                            "comp_attribute_value": "31",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 32,
                                            "comp_attribute_value_name": "Patch Fitting Door",
                                            "comp_attribute_value": "32",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 33,
                                            "comp_attribute_value_name": "Frameless hinged glass door",
                                            "comp_attribute_value": "33",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 34,
                                            "comp_attribute_value_name": "Bifold Door",
                                            "comp_attribute_value": "34",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 35,
                                            "comp_attribute_value_name": "Stile Double-leaf Door",
                                            "comp_attribute_value": "35",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 17,
                                    "component_attribute_name": "Single Glazed Profile (82 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 18,
                                    "component_attribute_name": "Single Glazed Gass Profile (82 X 40) Type -2",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 19,
                                    "component_attribute_name": "Single Glazed Profile (150 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 20,
                                    "component_attribute_name": "Double glazed Profile (82 X 55)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Frame (55 X 32)",
                                    "component_id": 21,
                                    "component_name": "Door Frame",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 22,
                                    "component_attribute_name": "Door Hinges",
                                    "component_id": 22,
                                    "component_name": "Door Hinges",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 23,
                                    "component_attribute_name": "Door Lock",
                                    "component_id": 23,
                                    "component_name": "Door lock",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 24,
                                    "component_attribute_name": "Colour",
                                    "component_id": 24,
                                    "component_name": "Color",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 36,
                                            "comp_attribute_value_name": "SS, black",
                                            "comp_attribute_value": "36",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 37,
                                            "comp_attribute_value_name": "White",
                                            "comp_attribute_value": "37",
                                            "isDefault": false
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "product_id": 9,
                    "product_name": "Door",
                    "templates": [
                        {
                            "template_id": 11,
                            "template_name": "Stile Door - Single leaf",
                            "template_img_url": null,
                            "attributes": [
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Height",
                                    "component_id": 21,
                                    "component_name": "Door Height",
                                    "component_type": "text",
                                    "min": 2000,
                                    "max": 3000,
                                    "defaultValue": 2400
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Glass",
                                    "component_id": 21,
                                    "component_name": "Door Frame",
                                    "component_type": "display",
                                    "defaultValue": "10mm Toughend Glass"
                                },
                                {
                                    "component_attribute_id": 22,
                                    "component_attribute_name": "Profile Finish",
                                    "component_id": 22,
                                    "component_name": "Profile Finish",
                                    "component_type": "dropdown",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 5,
                                            "comp_attribute_value_name": "Black Matt",
                                            "comp_attribute_value": "5",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 6,
                                            "comp_attribute_value_name": "Natural Anodized",
                                            "comp_attribute_value": "6",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Champagne",
                                            "comp_attribute_value": "7",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Rose Gold",
                                            "comp_attribute_value": "8",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Orientation",
                                    "component_id": 21,
                                    "component_name": "Orientation",
                                    "component_type": "dropdown",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Left",
                                            "comp_attribute_value": "7",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Right",
                                            "comp_attribute_value": "8",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Opening",
                                    "component_id": 21,
                                    "component_name": "Opening",
                                    "component_type": "dropdown",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Push",
                                            "comp_attribute_value": "7",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Pull",
                                            "comp_attribute_value": "8",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Handle",
                                    "component_id": 21,
                                    "component_name": "Door Handle",
                                    "component_type": "dropdown",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Lever Handle",
                                            "comp_attribute_value": "7",
                                            "make_brand": true,
                                            "make_component": "dropdown",
                                            "make_values": [
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Saint Gobain",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": true
                                                },
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Others",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": false
                                                }
                                            ],
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Offset Handle",
                                            "comp_attribute_value": "8",
                                            "isDefault": false,
                                            "make_brand": true,
                                            "make_component": "dropdown",
                                            "make_values": [
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Saint Gobain",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": true
                                                },
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Others",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": false
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Lock",
                                    "component_id": 21,
                                    "component_name": "Door Lock",
                                    "component_type": "dropdown",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Sash Lock",
                                            "comp_attribute_value": "7",
                                            "make_brand": true,
                                            "make_component": "dropdown",
                                            "make_values": [
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Saint Gobain",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": true
                                                },
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Others",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": false
                                                }
                                            ],
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Dead Lock",
                                            "comp_attribute_value": "8",
                                            "isDefault": false,
                                            "make_brand": true,
                                            "make_component": "dropdown",
                                            "make_values": [
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Saint Gobain",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": true
                                                },
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Others",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": false
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Closer",
                                    "component_id": 21,
                                    "component_name": "Door Closer",
                                    "component_type": "dropdown",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Exposed Closer",
                                            "comp_attribute_value": "7",
                                            "make_brand": true,
                                            "make_component": "dropdown",
                                            "make_values": [
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Saint Gobain",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": true
                                                },
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Others",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": false
                                                }
                                            ],
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Concealed Closer",
                                            "comp_attribute_value": "8",
                                            "isDefault": false,
                                            "make_brand": true,
                                            "make_component": "dropdown",
                                            "make_values": [
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Saint Gobain",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": true
                                                },
                                                {
                                                    "comp_attribute_value_id": 7,
                                                    "comp_attribute_value_name": "Others",
                                                    "comp_attribute_value": "7",
                                                    "isDefault": false
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Stopper",
                                    "component_id": 21,
                                    "component_name": "Door Stopper",
                                    "component_type": "radio",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "No Stopper",
                                            "comp_attribute_value": "7",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Others",
                                            "comp_attribute_value": "8",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Tower Bolt",
                                    "component_id": 21,
                                    "component_name": "Tower Bolt",
                                    "component_type": "radio",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "No Tower Bolt",
                                            "comp_attribute_value": "7",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Others",
                                            "comp_attribute_value": "8",
                                            "isDefault": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "template_id": 11,
                            "template_name": "Sliding Door",
                            "template_img_url": null
                        },
                        {
                            "template_id": 11,
                            "template_name": "Patch Fitting Door",
                            "template_img_url": null
                        }
                    ]
                }
            ]
        },
        {
            "system_id": 18,
            "system_name": "Octa",
            "products": [
                {
                    "product_id": 8,
                    "product_name": "Partition",
                    "templates": [
                        {
                            "template_id": 14,
                            "template_name": "Partition with Mullion",
                            "template_img_url": null,
                            "attributes": [
                                {
                                    "component_attribute_id": 6,
                                    "component_attribute_name": "Profile Constant",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 1,
                                            "comp_attribute_value_name": "U- Profile",
                                            "comp_attribute_value": "1",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 2,
                                            "comp_attribute_value_name": "Clip on Profile ",
                                            "comp_attribute_value": "2",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 3,
                                            "comp_attribute_value_name": "90 Degree Alu Profile",
                                            "comp_attribute_value": "3",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 7,
                                    "component_attribute_name": "Profile Finish",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 4,
                                            "comp_attribute_value_name": "Raw Mill",
                                            "comp_attribute_value": "4",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 5,
                                            "comp_attribute_value_name": "Black",
                                            "comp_attribute_value": "5",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 6,
                                            "comp_attribute_value_name": "Natural Anodized",
                                            "comp_attribute_value": "6",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Champagne",
                                            "comp_attribute_value": "7",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Rose Gold",
                                            "comp_attribute_value": "8",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "Customised RAL colours",
                                            "comp_attribute_value": "9",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 8,
                                    "component_attribute_name": "Glass Thickness",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 10,
                                            "comp_attribute_value_name": "11.52 PVB/APVB",
                                            "comp_attribute_value": "10",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 11,
                                            "comp_attribute_value_name": "12",
                                            "comp_attribute_value": "11",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 12,
                                            "comp_attribute_value_name": "12.52 PVB/APVB",
                                            "comp_attribute_value": "12",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 13,
                                            "comp_attribute_value_name": "13.52 PVB/APVB",
                                            "comp_attribute_value": "13",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 9,
                                    "component_attribute_name": "Acoustic Value",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 14,
                                            "comp_attribute_value_name": "34dB",
                                            "comp_attribute_value": "14",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 15,
                                            "comp_attribute_value_name": "36dB",
                                            "comp_attribute_value": "15",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 16,
                                            "comp_attribute_value_name": "38dB/36dB",
                                            "comp_attribute_value": "16",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 10,
                                    "component_attribute_name": "Polycarbonates Size",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 17,
                                            "comp_attribute_value_name": "10",
                                            "comp_attribute_value": "17",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 18,
                                            "comp_attribute_value_name": "11.52",
                                            "comp_attribute_value": "18",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 19,
                                            "comp_attribute_value_name": "12.52",
                                            "comp_attribute_value": "19",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 20,
                                            "comp_attribute_value_name": "13.52",
                                            "comp_attribute_value": "20",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 21,
                                            "comp_attribute_value_name": "Customized",
                                            "comp_attribute_value": "21",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 11,
                                    "component_attribute_name": "Polycarbonates Shape",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 22,
                                            "comp_attribute_value_name": "2-Way",
                                            "comp_attribute_value": "22",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 23,
                                            "comp_attribute_value_name": "3-Way",
                                            "comp_attribute_value": "23",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 24,
                                            "comp_attribute_value_name": "90 Degree",
                                            "comp_attribute_value": "24",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 25,
                                            "comp_attribute_value_name": "Customised",
                                            "comp_attribute_value": "25",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 13,
                                    "component_attribute_name": "Gasket",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 14,
                                    "component_attribute_name": "Gasket Color",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 15,
                                    "component_attribute_name": "Portal",
                                    "component_id": 19,
                                    "component_name": "Accessories",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 26,
                                            "comp_attribute_value_name": "100 X 100 Portal",
                                            "comp_attribute_value": "26",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 27,
                                            "comp_attribute_value_name": "200 X 100 Portal",
                                            "comp_attribute_value": "27",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 28,
                                            "comp_attribute_value_name": "300 X 100portal",
                                            "comp_attribute_value": "28",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 29,
                                            "comp_attribute_value_name": "80 X 70 portal",
                                            "comp_attribute_value": "29",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 16,
                                    "component_attribute_name": "Door Types",
                                    "component_id": 20,
                                    "component_name": "Door Types",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 30,
                                            "comp_attribute_value_name": "Stile Single-leaf Door",
                                            "comp_attribute_value": "30",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 31,
                                            "comp_attribute_value_name": "Sliding Door",
                                            "comp_attribute_value": "31",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 32,
                                            "comp_attribute_value_name": "Patch Fitting Door",
                                            "comp_attribute_value": "32",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 33,
                                            "comp_attribute_value_name": "Frameless hinged glass door",
                                            "comp_attribute_value": "33",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 34,
                                            "comp_attribute_value_name": "Bifold Door",
                                            "comp_attribute_value": "34",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 35,
                                            "comp_attribute_value_name": "Stile Double-leaf Door",
                                            "comp_attribute_value": "35",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 17,
                                    "component_attribute_name": "Single Glazed Profile (82 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 18,
                                    "component_attribute_name": "Single Glazed Gass Profile (82 X 40) Type -2",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 19,
                                    "component_attribute_name": "Single Glazed Profile (150 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 20,
                                    "component_attribute_name": "Double glazed Profile (82 X 55)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Frame (55 X 32)",
                                    "component_id": 21,
                                    "component_name": "Door Frame",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 22,
                                    "component_attribute_name": "Door Hinges",
                                    "component_id": 22,
                                    "component_name": "Door Hinges",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 23,
                                    "component_attribute_name": "Door Lock",
                                    "component_id": 23,
                                    "component_name": "Door lock",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 24,
                                    "component_attribute_name": "Colour",
                                    "component_id": 24,
                                    "component_name": "Color",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 36,
                                            "comp_attribute_value_name": "SS, black",
                                            "comp_attribute_value": "36",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 37,
                                            "comp_attribute_value_name": "White",
                                            "comp_attribute_value": "37",
                                            "isDefault": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "template_id": 15,
                            "template_name": "Partition with Jcap",
                            "template_img_url": null,
                            "attributes": [
                                {
                                    "component_attribute_id": 6,
                                    "component_attribute_name": "Profile Constant",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 1,
                                            "comp_attribute_value_name": "U- Profile",
                                            "comp_attribute_value": "1",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 2,
                                            "comp_attribute_value_name": "Clip on Profile ",
                                            "comp_attribute_value": "2",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 3,
                                            "comp_attribute_value_name": "90 Degree Alu Profile",
                                            "comp_attribute_value": "3",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 7,
                                    "component_attribute_name": "Profile Finish",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 4,
                                            "comp_attribute_value_name": "Raw Mill",
                                            "comp_attribute_value": "4",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 5,
                                            "comp_attribute_value_name": "Black",
                                            "comp_attribute_value": "5",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 6,
                                            "comp_attribute_value_name": "Natural Anodized",
                                            "comp_attribute_value": "6",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Champagne",
                                            "comp_attribute_value": "7",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Rose Gold",
                                            "comp_attribute_value": "8",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "Customised RAL colours",
                                            "comp_attribute_value": "9",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 8,
                                    "component_attribute_name": "Glass Thickness",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 10,
                                            "comp_attribute_value_name": "11.52 PVB/APVB",
                                            "comp_attribute_value": "10",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 11,
                                            "comp_attribute_value_name": "12",
                                            "comp_attribute_value": "11",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 12,
                                            "comp_attribute_value_name": "12.52 PVB/APVB",
                                            "comp_attribute_value": "12",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 13,
                                            "comp_attribute_value_name": "13.52 PVB/APVB",
                                            "comp_attribute_value": "13",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 9,
                                    "component_attribute_name": "Acoustic Value",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 14,
                                            "comp_attribute_value_name": "34dB",
                                            "comp_attribute_value": "14",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 15,
                                            "comp_attribute_value_name": "36dB",
                                            "comp_attribute_value": "15",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 16,
                                            "comp_attribute_value_name": "38dB/36dB",
                                            "comp_attribute_value": "16",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 10,
                                    "component_attribute_name": "Polycarbonates Size",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 17,
                                            "comp_attribute_value_name": "10",
                                            "comp_attribute_value": "17",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 18,
                                            "comp_attribute_value_name": "11.52",
                                            "comp_attribute_value": "18",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 19,
                                            "comp_attribute_value_name": "12.52",
                                            "comp_attribute_value": "19",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 20,
                                            "comp_attribute_value_name": "13.52",
                                            "comp_attribute_value": "20",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 21,
                                            "comp_attribute_value_name": "Customized",
                                            "comp_attribute_value": "21",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 11,
                                    "component_attribute_name": "Polycarbonates Shape",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 22,
                                            "comp_attribute_value_name": "2-Way",
                                            "comp_attribute_value": "22",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 23,
                                            "comp_attribute_value_name": "3-Way",
                                            "comp_attribute_value": "23",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 24,
                                            "comp_attribute_value_name": "90 Degree",
                                            "comp_attribute_value": "24",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 25,
                                            "comp_attribute_value_name": "Customised",
                                            "comp_attribute_value": "25",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 13,
                                    "component_attribute_name": "Gasket",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 14,
                                    "component_attribute_name": "Gasket Color",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 15,
                                    "component_attribute_name": "Portal",
                                    "component_id": 19,
                                    "component_name": "Accessories",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 26,
                                            "comp_attribute_value_name": "100 X 100 Portal",
                                            "comp_attribute_value": "26",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 27,
                                            "comp_attribute_value_name": "200 X 100 Portal",
                                            "comp_attribute_value": "27",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 28,
                                            "comp_attribute_value_name": "300 X 100portal",
                                            "comp_attribute_value": "28",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 29,
                                            "comp_attribute_value_name": "80 X 70 portal",
                                            "comp_attribute_value": "29",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 16,
                                    "component_attribute_name": "Door Types",
                                    "component_id": 20,
                                    "component_name": "Door Types",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 30,
                                            "comp_attribute_value_name": "Stile Single-leaf Door",
                                            "comp_attribute_value": "30",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 31,
                                            "comp_attribute_value_name": "Sliding Door",
                                            "comp_attribute_value": "31",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 32,
                                            "comp_attribute_value_name": "Patch Fitting Door",
                                            "comp_attribute_value": "32",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 33,
                                            "comp_attribute_value_name": "Frameless hinged glass door",
                                            "comp_attribute_value": "33",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 34,
                                            "comp_attribute_value_name": "Bifold Door",
                                            "comp_attribute_value": "34",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 35,
                                            "comp_attribute_value_name": "Stile Double-leaf Door",
                                            "comp_attribute_value": "35",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 17,
                                    "component_attribute_name": "Single Glazed Profile (82 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 18,
                                    "component_attribute_name": "Single Glazed Gass Profile (82 X 40) Type -2",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 19,
                                    "component_attribute_name": "Single Glazed Profile (150 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 20,
                                    "component_attribute_name": "Double glazed Profile (82 X 55)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Frame (55 X 32)",
                                    "component_id": 21,
                                    "component_name": "Door Frame",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 22,
                                    "component_attribute_name": "Door Hinges",
                                    "component_id": 22,
                                    "component_name": "Door Hinges",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 23,
                                    "component_attribute_name": "Door Lock",
                                    "component_id": 23,
                                    "component_name": "Door lock",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 24,
                                    "component_attribute_name": "Colour",
                                    "component_id": 24,
                                    "component_name": "Color",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 36,
                                            "comp_attribute_value_name": "SS, black",
                                            "comp_attribute_value": "36",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 37,
                                            "comp_attribute_value_name": "White",
                                            "comp_attribute_value": "37",
                                            "isDefault": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "template_id": 16,
                            "template_name": "Partition with Polycarbonate",
                            "template_img_url": null,
                            "attributes": [
                                {
                                    "component_attribute_id": 6,
                                    "component_attribute_name": "Profile Constant",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 1,
                                            "comp_attribute_value_name": "U- Profile",
                                            "comp_attribute_value": "1",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 2,
                                            "comp_attribute_value_name": "Clip on Profile ",
                                            "comp_attribute_value": "2",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 3,
                                            "comp_attribute_value_name": "90 Degree Alu Profile",
                                            "comp_attribute_value": "3",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 7,
                                    "component_attribute_name": "Profile Finish",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 4,
                                            "comp_attribute_value_name": "Raw Mill",
                                            "comp_attribute_value": "4",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 5,
                                            "comp_attribute_value_name": "Black",
                                            "comp_attribute_value": "5",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 6,
                                            "comp_attribute_value_name": "Natural Anodized",
                                            "comp_attribute_value": "6",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Champagne",
                                            "comp_attribute_value": "7",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Rose Gold",
                                            "comp_attribute_value": "8",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "Customised RAL colours",
                                            "comp_attribute_value": "9",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 8,
                                    "component_attribute_name": "Glass Thickness",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 10,
                                            "comp_attribute_value_name": "11.52 PVB/APVB",
                                            "comp_attribute_value": "10",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 11,
                                            "comp_attribute_value_name": "12",
                                            "comp_attribute_value": "11",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 12,
                                            "comp_attribute_value_name": "12.52 PVB/APVB",
                                            "comp_attribute_value": "12",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 13,
                                            "comp_attribute_value_name": "13.52 PVB/APVB",
                                            "comp_attribute_value": "13",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 9,
                                    "component_attribute_name": "Acoustic Value",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 14,
                                            "comp_attribute_value_name": "34dB",
                                            "comp_attribute_value": "14",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 15,
                                            "comp_attribute_value_name": "36dB",
                                            "comp_attribute_value": "15",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 16,
                                            "comp_attribute_value_name": "38dB/36dB",
                                            "comp_attribute_value": "16",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 10,
                                    "component_attribute_name": "Polycarbonates Size",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 17,
                                            "comp_attribute_value_name": "10",
                                            "comp_attribute_value": "17",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 18,
                                            "comp_attribute_value_name": "11.52",
                                            "comp_attribute_value": "18",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 19,
                                            "comp_attribute_value_name": "12.52",
                                            "comp_attribute_value": "19",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 20,
                                            "comp_attribute_value_name": "13.52",
                                            "comp_attribute_value": "20",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 21,
                                            "comp_attribute_value_name": "Customized",
                                            "comp_attribute_value": "21",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 11,
                                    "component_attribute_name": "Polycarbonates Shape",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 22,
                                            "comp_attribute_value_name": "2-Way",
                                            "comp_attribute_value": "22",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 23,
                                            "comp_attribute_value_name": "3-Way",
                                            "comp_attribute_value": "23",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 24,
                                            "comp_attribute_value_name": "90 Degree",
                                            "comp_attribute_value": "24",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 25,
                                            "comp_attribute_value_name": "Customised",
                                            "comp_attribute_value": "25",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 13,
                                    "component_attribute_name": "Gasket",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 14,
                                    "component_attribute_name": "Gasket Color",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 15,
                                    "component_attribute_name": "Portal",
                                    "component_id": 19,
                                    "component_name": "Accessories",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 26,
                                            "comp_attribute_value_name": "100 X 100 Portal",
                                            "comp_attribute_value": "26",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 27,
                                            "comp_attribute_value_name": "200 X 100 Portal",
                                            "comp_attribute_value": "27",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 28,
                                            "comp_attribute_value_name": "300 X 100portal",
                                            "comp_attribute_value": "28",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 29,
                                            "comp_attribute_value_name": "80 X 70 portal",
                                            "comp_attribute_value": "29",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 16,
                                    "component_attribute_name": "Door Types",
                                    "component_id": 20,
                                    "component_name": "Door Types",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 30,
                                            "comp_attribute_value_name": "Stile Single-leaf Door",
                                            "comp_attribute_value": "30",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 31,
                                            "comp_attribute_value_name": "Sliding Door",
                                            "comp_attribute_value": "31",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 32,
                                            "comp_attribute_value_name": "Patch Fitting Door",
                                            "comp_attribute_value": "32",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 33,
                                            "comp_attribute_value_name": "Frameless hinged glass door",
                                            "comp_attribute_value": "33",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 34,
                                            "comp_attribute_value_name": "Bifold Door",
                                            "comp_attribute_value": "34",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 35,
                                            "comp_attribute_value_name": "Stile Double-leaf Door",
                                            "comp_attribute_value": "35",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 17,
                                    "component_attribute_name": "Single Glazed Profile (82 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 18,
                                    "component_attribute_name": "Single Glazed Gass Profile (82 X 40) Type -2",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 19,
                                    "component_attribute_name": "Single Glazed Profile (150 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 20,
                                    "component_attribute_name": "Double glazed Profile (82 X 55)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Frame (55 X 32)",
                                    "component_id": 21,
                                    "component_name": "Door Frame",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 22,
                                    "component_attribute_name": "Door Hinges",
                                    "component_id": 22,
                                    "component_name": "Door Hinges",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 23,
                                    "component_attribute_name": "Door Lock",
                                    "component_id": 23,
                                    "component_name": "Door lock",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 24,
                                    "component_attribute_name": "Colour",
                                    "component_id": 24,
                                    "component_name": "Color",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 36,
                                            "comp_attribute_value_name": "SS, black",
                                            "comp_attribute_value": "36",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 37,
                                            "comp_attribute_value_name": "White",
                                            "comp_attribute_value": "37",
                                            "isDefault": false
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "product_id": 9,
                    "product_name": "Door",
                    "templates": []
                }
            ]
        },
        {
            "system_id": 6,
            "system_name": "Hexa",
            "products": []
        },
        {
            "system_id": 4,
            "system_name": "Influence",
            "products": [
                {
                    "product_id": 1,
                    "product_name": "Door",
                    "templates": [
                        {
                            "template_id": 20,
                            "template_name": "Patch Fillig Door",
                            "template_img_url": null,
                            "attributes": [
                                {
                                    "component_attribute_id": 6,
                                    "component_attribute_name": "Profile Constant",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 1,
                                            "comp_attribute_value_name": "U- Profile",
                                            "comp_attribute_value": "1",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 2,
                                            "comp_attribute_value_name": "Clip on Profile ",
                                            "comp_attribute_value": "2",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 3,
                                            "comp_attribute_value_name": "90 Degree Alu Profile",
                                            "comp_attribute_value": "3",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 7,
                                    "component_attribute_name": "Profile Finish",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 4,
                                            "comp_attribute_value_name": "Raw Mill",
                                            "comp_attribute_value": "4",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 5,
                                            "comp_attribute_value_name": "Black",
                                            "comp_attribute_value": "5",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 6,
                                            "comp_attribute_value_name": "Natural Anodized",
                                            "comp_attribute_value": "6",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Champagne",
                                            "comp_attribute_value": "7",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Rose Gold",
                                            "comp_attribute_value": "8",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "Customised RAL colours",
                                            "comp_attribute_value": "9",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 8,
                                    "component_attribute_name": "Glass Thickness",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 10,
                                            "comp_attribute_value_name": "11.52 PVB/APVB",
                                            "comp_attribute_value": "10",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 11,
                                            "comp_attribute_value_name": "12",
                                            "comp_attribute_value": "11",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 12,
                                            "comp_attribute_value_name": "12.52 PVB/APVB",
                                            "comp_attribute_value": "12",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 13,
                                            "comp_attribute_value_name": "13.52 PVB/APVB",
                                            "comp_attribute_value": "13",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 9,
                                    "component_attribute_name": "Acoustic Value",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 14,
                                            "comp_attribute_value_name": "34dB",
                                            "comp_attribute_value": "14",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 15,
                                            "comp_attribute_value_name": "36dB",
                                            "comp_attribute_value": "15",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 16,
                                            "comp_attribute_value_name": "38dB/36dB",
                                            "comp_attribute_value": "16",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 10,
                                    "component_attribute_name": "Polycarbonates Size",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 17,
                                            "comp_attribute_value_name": "10",
                                            "comp_attribute_value": "17",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 18,
                                            "comp_attribute_value_name": "11.52",
                                            "comp_attribute_value": "18",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 19,
                                            "comp_attribute_value_name": "12.52",
                                            "comp_attribute_value": "19",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 20,
                                            "comp_attribute_value_name": "13.52",
                                            "comp_attribute_value": "20",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 21,
                                            "comp_attribute_value_name": "Customized",
                                            "comp_attribute_value": "21",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 11,
                                    "component_attribute_name": "Polycarbonates Shape",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 22,
                                            "comp_attribute_value_name": "2-Way",
                                            "comp_attribute_value": "22",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 23,
                                            "comp_attribute_value_name": "3-Way",
                                            "comp_attribute_value": "23",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 24,
                                            "comp_attribute_value_name": "90 Degree",
                                            "comp_attribute_value": "24",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 25,
                                            "comp_attribute_value_name": "Customised",
                                            "comp_attribute_value": "25",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 13,
                                    "component_attribute_name": "Gasket",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 14,
                                    "component_attribute_name": "Gasket Color",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 15,
                                    "component_attribute_name": "Portal",
                                    "component_id": 19,
                                    "component_name": "Accessories",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 26,
                                            "comp_attribute_value_name": "100 X 100 Portal",
                                            "comp_attribute_value": "26",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 27,
                                            "comp_attribute_value_name": "200 X 100 Portal",
                                            "comp_attribute_value": "27",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 28,
                                            "comp_attribute_value_name": "300 X 100portal",
                                            "comp_attribute_value": "28",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 29,
                                            "comp_attribute_value_name": "80 X 70 portal",
                                            "comp_attribute_value": "29",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 16,
                                    "component_attribute_name": "Door Types",
                                    "component_id": 20,
                                    "component_name": "Door Types",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 30,
                                            "comp_attribute_value_name": "Stile Single-leaf Door",
                                            "comp_attribute_value": "30",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 31,
                                            "comp_attribute_value_name": "Sliding Door",
                                            "comp_attribute_value": "31",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 32,
                                            "comp_attribute_value_name": "Patch Fitting Door",
                                            "comp_attribute_value": "32",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 33,
                                            "comp_attribute_value_name": "Frameless hinged glass door",
                                            "comp_attribute_value": "33",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 34,
                                            "comp_attribute_value_name": "Bifold Door",
                                            "comp_attribute_value": "34",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 35,
                                            "comp_attribute_value_name": "Stile Double-leaf Door",
                                            "comp_attribute_value": "35",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 17,
                                    "component_attribute_name": "Single Glazed Profile (82 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 18,
                                    "component_attribute_name": "Single Glazed Gass Profile (82 X 40) Type -2",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 19,
                                    "component_attribute_name": "Single Glazed Profile (150 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 20,
                                    "component_attribute_name": "Double glazed Profile (82 X 55)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Frame (55 X 32)",
                                    "component_id": 21,
                                    "component_name": "Door Frame",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 22,
                                    "component_attribute_name": "Door Hinges",
                                    "component_id": 22,
                                    "component_name": "Door Hinges",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 23,
                                    "component_attribute_name": "Door Lock",
                                    "component_id": 23,
                                    "component_name": "Door lock",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 24,
                                    "component_attribute_name": "Colour",
                                    "component_id": 24,
                                    "component_name": "Color",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 36,
                                            "comp_attribute_value_name": "SS, black",
                                            "comp_attribute_value": "36",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 37,
                                            "comp_attribute_value_name": "White",
                                            "comp_attribute_value": "37",
                                            "isDefault": true
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "template_id": 21,
                            "template_name": "Sliding Door",
                            "template_img_url": null,
                            "attributes": [
                                {
                                    "component_attribute_id": 6,
                                    "component_attribute_name": "Profile Constant",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 1,
                                            "comp_attribute_value_name": "U- Profile",
                                            "comp_attribute_value": "1",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 2,
                                            "comp_attribute_value_name": "Clip on Profile ",
                                            "comp_attribute_value": "2",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 3,
                                            "comp_attribute_value_name": "90 Degree Alu Profile",
                                            "comp_attribute_value": "3",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 7,
                                    "component_attribute_name": "Profile Finish",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 4,
                                            "comp_attribute_value_name": "Raw Mill",
                                            "comp_attribute_value": "4",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 5,
                                            "comp_attribute_value_name": "Black",
                                            "comp_attribute_value": "5",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 6,
                                            "comp_attribute_value_name": "Natural Anodized",
                                            "comp_attribute_value": "6",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Champagne",
                                            "comp_attribute_value": "7",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Rose Gold",
                                            "comp_attribute_value": "8",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "Customised RAL colours",
                                            "comp_attribute_value": "9",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 8,
                                    "component_attribute_name": "Glass Thickness",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 10,
                                            "comp_attribute_value_name": "11.52 PVB/APVB",
                                            "comp_attribute_value": "10",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 11,
                                            "comp_attribute_value_name": "12",
                                            "comp_attribute_value": "11",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 12,
                                            "comp_attribute_value_name": "12.52 PVB/APVB",
                                            "comp_attribute_value": "12",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 13,
                                            "comp_attribute_value_name": "13.52 PVB/APVB",
                                            "comp_attribute_value": "13",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 9,
                                    "component_attribute_name": "Acoustic Value",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 14,
                                            "comp_attribute_value_name": "34dB",
                                            "comp_attribute_value": "14",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 15,
                                            "comp_attribute_value_name": "36dB",
                                            "comp_attribute_value": "15",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 16,
                                            "comp_attribute_value_name": "38dB/36dB",
                                            "comp_attribute_value": "16",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 10,
                                    "component_attribute_name": "Polycarbonates Size",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 17,
                                            "comp_attribute_value_name": "10",
                                            "comp_attribute_value": "17",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 18,
                                            "comp_attribute_value_name": "11.52",
                                            "comp_attribute_value": "18",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 19,
                                            "comp_attribute_value_name": "12.52",
                                            "comp_attribute_value": "19",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 20,
                                            "comp_attribute_value_name": "13.52",
                                            "comp_attribute_value": "20",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 21,
                                            "comp_attribute_value_name": "Customized",
                                            "comp_attribute_value": "21",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 11,
                                    "component_attribute_name": "Polycarbonates Shape",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 22,
                                            "comp_attribute_value_name": "2-Way",
                                            "comp_attribute_value": "22",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 23,
                                            "comp_attribute_value_name": "3-Way",
                                            "comp_attribute_value": "23",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 24,
                                            "comp_attribute_value_name": "90 Degree",
                                            "comp_attribute_value": "24",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 25,
                                            "comp_attribute_value_name": "Customised",
                                            "comp_attribute_value": "25",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 13,
                                    "component_attribute_name": "Gasket",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 14,
                                    "component_attribute_name": "Gasket Color",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 15,
                                    "component_attribute_name": "Portal",
                                    "component_id": 19,
                                    "component_name": "Accessories",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 26,
                                            "comp_attribute_value_name": "100 X 100 Portal",
                                            "comp_attribute_value": "26",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 27,
                                            "comp_attribute_value_name": "200 X 100 Portal",
                                            "comp_attribute_value": "27",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 28,
                                            "comp_attribute_value_name": "300 X 100portal",
                                            "comp_attribute_value": "28",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 29,
                                            "comp_attribute_value_name": "80 X 70 portal",
                                            "comp_attribute_value": "29",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 16,
                                    "component_attribute_name": "Door Types",
                                    "component_id": 20,
                                    "component_name": "Door Types",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 30,
                                            "comp_attribute_value_name": "Stile Single-leaf Door",
                                            "comp_attribute_value": "30",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 31,
                                            "comp_attribute_value_name": "Sliding Door",
                                            "comp_attribute_value": "31",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 32,
                                            "comp_attribute_value_name": "Patch Fitting Door",
                                            "comp_attribute_value": "32",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 33,
                                            "comp_attribute_value_name": "Frameless hinged glass door",
                                            "comp_attribute_value": "33",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 34,
                                            "comp_attribute_value_name": "Bifold Door",
                                            "comp_attribute_value": "34",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 35,
                                            "comp_attribute_value_name": "Stile Double-leaf Door",
                                            "comp_attribute_value": "35",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 17,
                                    "component_attribute_name": "Single Glazed Profile (82 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 18,
                                    "component_attribute_name": "Single Glazed Gass Profile (82 X 40) Type -2",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 19,
                                    "component_attribute_name": "Single Glazed Profile (150 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 20,
                                    "component_attribute_name": "Double glazed Profile (82 X 55)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Frame (55 X 32)",
                                    "component_id": 21,
                                    "component_name": "Door Frame",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 22,
                                    "component_attribute_name": "Door Hinges",
                                    "component_id": 22,
                                    "component_name": "Door Hinges",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 23,
                                    "component_attribute_name": "Door Lock",
                                    "component_id": 23,
                                    "component_name": "Door lock",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 24,
                                    "component_attribute_name": "Colour",
                                    "component_id": 24,
                                    "component_name": "Color",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 36,
                                            "comp_attribute_value_name": "SS, black",
                                            "comp_attribute_value": "36",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 37,
                                            "comp_attribute_value_name": "White",
                                            "comp_attribute_value": "37",
                                            "isDefault": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "template_id": 22,
                            "template_name": "Stile Single leaf Door",
                            "template_img_url": null,
                            "attributes": [
                                {
                                    "component_attribute_id": 6,
                                    "component_attribute_name": "Profile Constant",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 1,
                                            "comp_attribute_value_name": "U- Profile",
                                            "comp_attribute_value": "1",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 2,
                                            "comp_attribute_value_name": "Clip on Profile ",
                                            "comp_attribute_value": "2",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 3,
                                            "comp_attribute_value_name": "90 Degree Alu Profile",
                                            "comp_attribute_value": "3",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 7,
                                    "component_attribute_name": "Profile Finish",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 4,
                                            "comp_attribute_value_name": "Raw Mill",
                                            "comp_attribute_value": "4",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 5,
                                            "comp_attribute_value_name": "Black",
                                            "comp_attribute_value": "5",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 6,
                                            "comp_attribute_value_name": "Natural Anodized",
                                            "comp_attribute_value": "6",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 7,
                                            "comp_attribute_value_name": "Champagne",
                                            "comp_attribute_value": "7",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 8,
                                            "comp_attribute_value_name": "Rose Gold",
                                            "comp_attribute_value": "8",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 9,
                                            "comp_attribute_value_name": "Customised RAL colours",
                                            "comp_attribute_value": "9",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 8,
                                    "component_attribute_name": "Glass Thickness",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 10,
                                            "comp_attribute_value_name": "11.52 PVB/APVB",
                                            "comp_attribute_value": "10",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 11,
                                            "comp_attribute_value_name": "12",
                                            "comp_attribute_value": "11",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 12,
                                            "comp_attribute_value_name": "12.52 PVB/APVB",
                                            "comp_attribute_value": "12",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 13,
                                            "comp_attribute_value_name": "13.52 PVB/APVB",
                                            "comp_attribute_value": "13",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 9,
                                    "component_attribute_name": "Acoustic Value",
                                    "component_id": 16,
                                    "component_name": "Glass",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 14,
                                            "comp_attribute_value_name": "34dB",
                                            "comp_attribute_value": "14",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 15,
                                            "comp_attribute_value_name": "36dB",
                                            "comp_attribute_value": "15",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 16,
                                            "comp_attribute_value_name": "38dB/36dB",
                                            "comp_attribute_value": "16",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 10,
                                    "component_attribute_name": "Polycarbonates Size",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 17,
                                            "comp_attribute_value_name": "10",
                                            "comp_attribute_value": "17",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 18,
                                            "comp_attribute_value_name": "11.52",
                                            "comp_attribute_value": "18",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 19,
                                            "comp_attribute_value_name": "12.52",
                                            "comp_attribute_value": "19",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 20,
                                            "comp_attribute_value_name": "13.52",
                                            "comp_attribute_value": "20",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 21,
                                            "comp_attribute_value_name": "Customized",
                                            "comp_attribute_value": "21",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 11,
                                    "component_attribute_name": "Polycarbonates Shape",
                                    "component_id": 17,
                                    "component_name": "Polycarbonate",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 22,
                                            "comp_attribute_value_name": "2-Way",
                                            "comp_attribute_value": "22",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 23,
                                            "comp_attribute_value_name": "3-Way",
                                            "comp_attribute_value": "23",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 24,
                                            "comp_attribute_value_name": "90 Degree",
                                            "comp_attribute_value": "24",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 25,
                                            "comp_attribute_value_name": "Customised",
                                            "comp_attribute_value": "25",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 13,
                                    "component_attribute_name": "Gasket",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 14,
                                    "component_attribute_name": "Gasket Color",
                                    "component_id": 18,
                                    "component_name": "Gaskets",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 15,
                                    "component_attribute_name": "Portal",
                                    "component_id": 19,
                                    "component_name": "Accessories",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 26,
                                            "comp_attribute_value_name": "100 X 100 Portal",
                                            "comp_attribute_value": "26",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 27,
                                            "comp_attribute_value_name": "200 X 100 Portal",
                                            "comp_attribute_value": "27",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 28,
                                            "comp_attribute_value_name": "300 X 100portal",
                                            "comp_attribute_value": "28",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 29,
                                            "comp_attribute_value_name": "80 X 70 portal",
                                            "comp_attribute_value": "29",
                                            "isDefault": false
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 16,
                                    "component_attribute_name": "Door Types",
                                    "component_id": 20,
                                    "component_name": "Door Types",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 30,
                                            "comp_attribute_value_name": "Stile Single-leaf Door",
                                            "comp_attribute_value": "30",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 31,
                                            "comp_attribute_value_name": "Sliding Door",
                                            "comp_attribute_value": "31",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 32,
                                            "comp_attribute_value_name": "Patch Fitting Door",
                                            "comp_attribute_value": "32",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 33,
                                            "comp_attribute_value_name": "Frameless hinged glass door",
                                            "comp_attribute_value": "33",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 34,
                                            "comp_attribute_value_name": "Bifold Door",
                                            "comp_attribute_value": "34",
                                            "isDefault": false
                                        },
                                        {
                                            "comp_attribute_value_id": 35,
                                            "comp_attribute_value_name": "Stile Double-leaf Door",
                                            "comp_attribute_value": "35",
                                            "isDefault": true
                                        }
                                    ]
                                },
                                {
                                    "component_attribute_id": 17,
                                    "component_attribute_name": "Single Glazed Profile (82 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 18,
                                    "component_attribute_name": "Single Glazed Gass Profile (82 X 40) Type -2",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 19,
                                    "component_attribute_name": "Single Glazed Profile (150 X 40)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 20,
                                    "component_attribute_name": "Double glazed Profile (82 X 55)",
                                    "component_id": 15,
                                    "component_name": "Profile",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 21,
                                    "component_attribute_name": "Door Frame (55 X 32)",
                                    "component_id": 21,
                                    "component_name": "Door Frame",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 22,
                                    "component_attribute_name": "Door Hinges",
                                    "component_id": 22,
                                    "component_name": "Door Hinges",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 23,
                                    "component_attribute_name": "Door Lock",
                                    "component_id": 23,
                                    "component_name": "Door lock",
                                    "values": []
                                },
                                {
                                    "component_attribute_id": 24,
                                    "component_attribute_name": "Colour",
                                    "component_id": 24,
                                    "component_name": "Color",
                                    "values": [
                                        {
                                            "comp_attribute_value_id": 36,
                                            "comp_attribute_value_name": "SS, black",
                                            "comp_attribute_value": "36",
                                            "isDefault": true
                                        },
                                        {
                                            "comp_attribute_value_id": 37,
                                            "comp_attribute_value_name": "White",
                                            "comp_attribute_value": "37",
                                            "isDefault": false
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
  },
  isDesign: false,
  Data2D: [],
  Data3D:[],
  isSelected : false,
  DXFMode:'',
  ExpandProperties: false,
  DoorProperties : {
    "Door Height": "2400",
    Glass : "10mm Toughend Glass",
    // "Stile frame" : "Single - 82x40[13]",
    Finish : "Black matt",
    Orientation: "Left", 
    Opening: "Push",

    "Door Handle" : "",
    "Door Lock" : "",
    "Door closer" : "", 
    "Door dropdown seal" : "",

    "Door Stopper": "No Stopper",
    "Tower Bolt": "No Tower Bolt",
  },
  PartitionProperties : {
    "Partition Height": "2400",
    "Profile Finish": "Black Matt",
    "Glass Thickness": "10mm",
    "Glass Type": "Toughened Glass",
     Acoustic: "10mm",
    "Glass Joint": "Two-way Polycarbonate",
    "Gasket Color": "Default Black"
  },
  TwoD: {
    system_id: "",
    product_id: "",
    division: "", // sytems
    mode: "", // partition or Door
    type: "", // type of template
    divisionIndex: "",
    modeIndex: "",
    typeIndex: "",
    gridVisible: true, // to show grid / white
    unit: "mm", // switch btw inchen and mm
    history: [], // to store redo/undo
    redoStack: [], // Clear redo stack after a new action
    isUndoDisabled: true,
    isRedoDisabled: true,
  },
  Rules:{}
};

export const FlagContext = createContext();
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const FlagProvider = ({ children }) => {
  const [flag, setFlag] = useState(initialFlagState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const updateFlag = (key, value, replace = false) => {
  //   setFlag((prevFlag) => ({
  //     ...prevFlag,
  //     [key]: replace
  //       ? value // Replace the object completely
  //       : typeof prevFlag[key] === 'object' && !Array.isArray(prevFlag[key])
  //       ? { ...prevFlag[key], ...value } // Merge the object if it's not being replaced
  //       : value, // Replace if it's an array or primitive
  //   }));
  // };

  const fetchTemplateAttributes = async () => {
    try {
      const response = await fetch(`${BASE_URL}/templatecomponentattributes/attributes/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      if (data.status === 'success') {
        const dataFromDB = data;
        const tranformData = await transformInputToOutput(data);
        console.log("Properties",tranformData);

        const FinalJson = await reorderAttributes(tranformData);

        console.log("Properties2",FinalJson);
        
        updateFlag("Properties", FinalJson, true)
        //resetFlag(properties);
      } else {
        setError('Failed to retrieve Attributes.');
      }
    } catch (err) {
      setError('An error occurred while fetching Attributes.');
    } finally {
      setLoading(false);
    }
  };

  async function transformInputToOutput(inputJson) {
    const output = { systems: [] };

    if (!inputJson || !inputJson.data || !Array.isArray(inputJson.data)) {
        return output;
    }
  
    const systemMap = new Map();
    inputJson.data.forEach(template => {
        const systemName = template.assigned_values[0].system_name || "Unknown";
        const productName = template.assigned_values[0].product_name || "Unknown";
        const systemId = template.assigned_values[0].system_id || "Unknown";
        const productId = template.assigned_values[0].product_id || "Unknown";

        // Check if system already exists, otherwise create new system object
        if (!systemMap.has(systemName)) {
            const newSystem = {
                system_id: systemId, // Assume a function to generate a unique ID
                system_name: systemName,
                products: []
            };
            systemMap.set(systemName, newSystem);
            output.systems.push(newSystem);
        }

        const system = systemMap.get(systemName);

        // Check if product already exists within the system
        let product = system.products.find(p => p.product_name === productName);
        if (!product) {
            product = {
                product_id: productId, // Assume a function to generate a unique ID
                product_name: productName,
                templates: []
            };
            system.products.push(product);
        }

        // Add template and map assigned values to attributes
        const newTemplate = {
            template_id: template.template_id,
            template_name: template.template_name,
            template_img_url: null,  // Placeholder, add if URL available
            attributes: []
        };

        // Group assigned values by component_attribute_name
        const attributeMap = new Map();
        template.assigned_values.forEach(value => {
            const attrName = value.component_attribute_name;
            if (!attributeMap.has(attrName)) {
                attributeMap.set(attrName, {
                    component_attribute_id: value.component_attribute_id,
                    component_attribute_name: attrName,
                    component_id: value.component_id,
                    component_name: value.component_name,  // Assume a function for missing names
                    component_type: value.component_type,
                    values: []
                });
            }

            // Add values within each attribute
            const attribute = attributeMap.get(attrName);
            attribute.values.push({
                comp_attribute_value_id: value.comp_attribute_value_id,
                comp_attribute_value_name: value.comp_attribute_value_name,
                comp_attribute_value: String(value.comp_attribute_value_id),
                isDefault: value.isdefault
            });
        });

        // Add mapped attributes to template
        newTemplate.attributes = Array.from(attributeMap.values());
        product.templates.push(newTemplate);
    });
    return output;
  }  

  function reorderAttributes(json) {
    json.systems.forEach((system) => {
        system.products.forEach((product) => {
            product.templates.forEach((template) => {
                // Sort attributes based on the provided order
                let ORDER;
                if(template.template_name === "Fixed Partition") ORDER = orderP
                else if(template.template_name === "Stile Door - Single leaf") ORDER = orderD

                if(ORDER){
                    template.attributes.sort((a, b) => {
                        const indexA = ORDER.indexOf(a.component_attribute_name);
                        const indexB = ORDER.indexOf(b.component_attribute_name);
    
                        // If not found in order list, move to the end
                        if (indexA === -1 && indexB === -1) return 0;
                        if (indexA === -1) return 1;
                        if (indexB === -1) return -1;
    
                        return indexA - indexB;
                    });
                }
            });
            if(product.product_name === "Partition"){
                product.templates[1] = {
                    "template_id": 12,
                    "template_name": "Partition with Jcap",
                    "template_img_url": null,
                    "attributes": []
                }
                product.templates[2] = {
                    "template_id": 13,
                    "template_name": "Partition with stick-on profile",
                    "template_img_url": null,
                    "attributes": []
                }
            }
            if(product.product_name === "Door"){
                product.templates[1] = {
                    "template_id": 11,
                    "template_name": "Sliding Door",
                    "template_img_url": null,
                    "attributes": []
                }
                product.templates[2] = {
                    "template_id": 11,
                    "template_name": "Patch Fitting Door",
                    "template_img_url": null,
                    "attributes": []
                }
            }
        });
    });
    json.systems[1] = {
        "system_id": 18,
        "system_name": "Octa",
        "products": []
    }
    json.systems[2] = {
        "system_id": 6,
        "system_name": "Hexa",
        "products": []
    }
    json.systems[3] = {
        "system_id": 4,
        "system_name": "Influence",
        "products": []
    }
    return json;
}


const orderP = [
    "Partition Height",
    "Profile Finish",
    "Glass Type",
    "Glass Thickness",
    "Acoustic Value",
    "Glass Joint",
    "Gasket Color",
];
const orderD = [
    "Door Height",
    "Glass",
    "Profile Finish",
    "Hardware Finish",
    "Orientation",
    "Opening",
    "Glass Type",
    "Glass Thickness",
    "Acoustic Value",
    "Door Handle",
    "Door Lock",
    "Door Closer",
    "Door Stopper",
    "Tower Bolt",
    "Offset Handle",
    "Dead Lock",
];


  useEffect( () => {
    fetchTemplateAttributes();
  },[])
  
  useEffect( () => {
   console.log(flag)
  },[flag])

  function isRealObject(value) {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  }

  const updateFlag = (keyOrUpdates, value, replace = false) => {
    // If keyOrUpdates is an object, it means multiple keys are being updated at once
    if (isRealObject(keyOrUpdates)) {
      setFlag((prevFlag) => ({
        ...prevFlag,
        ...Object.keys(keyOrUpdates).reduce((acc, key) => {
          acc[key] =
          isRealObject(prevFlag[key])
              ? { ...prevFlag[key], ...keyOrUpdates[key] }
              : keyOrUpdates[key];
          return acc;
        }, {}),
      }));
    } else {
      // Update a single key (either replace or merge based on `replace` flag)
      setFlag((prevFlag) => ({
        ...prevFlag,
        [keyOrUpdates]: replace
          ? value // Replace the object completely
          : isRealObject(prevFlag[keyOrUpdates])
          ? { ...prevFlag[keyOrUpdates], ...value } // Merge the object if it's not being replaced
          : value, // Replace if it's an array or primitive
      }));
    }
  };



  // updateFlag("Data2D", []); // Clear Data2D and merge updates
  // updateFlag("TwoD", { mode: "mode", type: "type" }); // Merge mode and type into TwoD
  // updateFlag("TwoD", { mode: "newMode" }, true); // Replace TwoD completely if replace=true
  // updateFlag({
  //   Data2D: [], // Clear Data2D
  //   TwoD: { mode: line.mode, type: line.type }, // Update TwoD with mode and type
  // });
  // resetFlag("TwoD") // to reset to default


  
  const resetFlag = (key) => {
    setFlag((prevFlag) => ({
      ...prevFlag,
      [key]: initialFlagState[key], // Reset the key to its default value
    }));
  };

  return (
    <FlagContext.Provider value={{ flag, updateFlag, resetFlag }}>
      {children}
    </FlagContext.Provider>
  );
};

export default FlagContext;